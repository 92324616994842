<template>
  <div>
    <section
      class="photos__upload_area"
      :class="''"
      @dragleave.prevent
      @dragover.prevent
      @drop.prevent="onFileChange"
    >
      <p class="mb-11">
        アップロードしたい動画をここにドロップ<br />
        (.mp4, .mkv, .mov, .avi, .flv, .mxf, .lxf, .gxf, .3gp, .webm, .mpg
        に対応)
      </p>
      <p class="mb-12">または</p>
      <v-btn rounded outlined @click="selectFile">ファイルを選択</v-btn>
      <input ref="file" type="file" @change="onFileChange" />
    </section>
  </div>
</template>

<script>
export default {
  name: "VideNewChoose",

  methods: {
    selectFile() {
      this.$refs.file.click();
    },
    onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (files.length === 0) {
        return;
      }
      this.$emit("add-video", files[0]);
    },
  },
};
</script>
