<template>
  <section class="col-7">
    <h2 v-if="$route.meta === 'new'" class="mb-10">動画新規登録</h2>
    <h2 v-if="$route.meta === 'edit'" class="mb-10">動画編集</h2>

    <div>
      <div class="subtitle-1 my-2">動画の選択</div>
      <div v-if="$route.meta === 'edit'" class="subtitle-1 my-2">
        動画ID: {{ $route.params.videoId }}
      </div>

      <VideoNewChoose v-if="!hasVideo" @add-video="addVideo" />
      <VideoNewPreview
        v-if="hasVideo"
        :video="video"
        :video-preview="videoPreview"
        @reset="reset"
      />

      <v-alert
        v-if="!hasVideo && isCheckedVideo"
        text
        color="error"
        class="my-8"
      >
        動画は、必須項目です。
      </v-alert>

      <div
        v-if="$route.meta === 'edit' && isShowedCancel"
        class="my-6 d-flex flex-column align-center"
      >
        <v-btn outlined @click="uploadCancel">キャンセル</v-btn>
      </div>
    </div>

    <div>
      <VideoNewForm
        :licenses="licenses"
        :licenses-page-url="licensesPageUrl"
        :statuses="statuses"
        :video="video"
        @upload-video-file="uploadVideoFile"
        @show-preview="showPreview"
        @check-video="checkVideo"
        @tap-loading-modal="tapLoadingModal"
      />
    </div>

    <ModalLoading
      :is-loading="isShowedLoading"
      :message="loadingMessage"
      :is-upload-error="isUploadError"
      :style="{ zIndex: 1000 }"
    />
  </section>
</template>
<script>
import VideoNewChoose from "@/components/VideoNewChoose.vue";
import VideoNewPreview from "@/components/VideoNewPreview.vue";
import VideoNewForm from "@/components/VideoNewForm.vue";

import { getData } from "@/axios";
import ModalLoading from "../../components/ModalLoading.vue";
import { createData } from "../../axios";

export default {
  name: "VideoNewAndEdit",

  components: {
    VideoNewChoose,
    VideoNewPreview,
    VideoNewForm,
    ModalLoading,
  },

  data() {
    return {
      ted: "",
      video: null,
      videoPreview: null,
      isShowedCancel: false,
      videoName: null,
      videoUrl: null,
      categories: null,
      tags: null,
      errorMessageVideo: null,
      isCheckedVideo: false,
      isShowedLoading: false,
      loadingMessage: "",
      isUploadError: false,
      licensesPageUrl: "https://creativecommons.jp/licenses",
      licenses: [
        {
          id: 1,
          value: "unknown",
          label: "不明",
        },
        {
          id: 2,
          value: "cc-by",
          label: "CC BY",
        },
        {
          id: 3,
          value: "cc-by-sa",
          label: "CC BY-SA",
        },
        {
          id: 4,
          value: "cc-by-nd",
          label: "CC BY-ND",
        },
        {
          id: 5,
          value: "cc-by-nc",
          label: "CC BY-NC",
        },
        {
          id: 6,
          value: "cc-by-nc-sa",
          label: "CC BY-NC-SA",
        },
        {
          id: 7,
          value: "cc-by-nc-nd",
          label: "CC BY-NC-ND",
        },
        {
          id: 8,
          value: "cc0",
          label: "CC0",
        },
        {
          id: 9,
          value: "all-rights-reserved",
          label: "All rights reserved",
        },
        {
          id: 10,
          value: "etc",
          label: "その他",
        },
      ],
      statuses: [
        {
          id: "status01",
          value: "unpublished",
          label: "下書き",
        },
        {
          id: "status02",
          value: "published",
          label: "公開",
        },
      ],
    };
  },
  async beforeRouteEnter(_to, _from, next) {
    await next(async (vm) => {
      vm.video = null;
      vm.videoPreview = null;

      await vm.getCategories();
      await vm.getTags();
    });
  },
  async beforeRouteUpdate(_to, _from, next) {
    await next(async (vm) => {
      vm.video = null;
      vm.videoPreview = null;

      await vm.getCategories();
      await vm.getTags();
    });
  },
  computed: {
    hasVideo: function () {
      return this.video && this.videoPreview;
    },
  },
  async mounted() {
    await this.getCategories();
    await this.getTags();
  },
  methods: {
    addVideo(file) {
      this.video = file;

      this.videoPreview = URL.createObjectURL(file);
    },
    reset() {
      this.video = null;
      this.videoPreview = null;

      this.isShowedCancel = true;
    },
    async getCategories() {
      const res = await getData("categories");
      this.categories = res.data;
    },
    async getTags() {
      const res = await getData("tags");
      this.tags = res.data;
    },
    async uploadVideoFile(videoObj) {
      const videoUploadUrl = videoObj.video_upload_url;
      this.tapLoadingModal(true, "動画のアップロード中...");
      console.log(videoObj, videoUploadUrl);

      const formData = new FormData();
      const uploadFile = new File(
        [this.video],
        `video_${videoObj.id}.${this.video.name.split(".").pop()}`
      );
      formData.append("file", uploadFile);
      createData("log", {
        payload: "VideoNew: uploadVideoFile(); 動画アップロード中",
      });

      const res = await fetch(videoUploadUrl, {
        method: "POST",
        body: formData,
      }).catch((e) => {
        console.log(e);
        createData("log", {
          payload: JSON.stringify(e, Object.getOwnPropertyNames(e)),
        });
        this.isUploadError = true;
        this.tapLoadingModal(
          true,
          "アップロードに失敗しました。（編集画面からアップロードをやり直してください）"
        );
        throw e;
      });

      if (!res.ok) {
        createData("log", {
          payload: `VideoNew: ${res.status}: ${res.text()}`,
        });
        this.isUploadError = true;
        this.tapLoadingModal(
          true,
          "アップロードに失敗しました。（編集画面からアップロードをやり直してください）"
        );
        console.log(res.text());
        return;
      }

      this.tapLoadingModal(false, "");
      const message =
        videoObj.status === "published"
          ? `「${videoObj.title}」を公開しました。`
          : `「${videoObj.title}」を下書きで保存しました。`;
      this.$store.dispatch("snackbar/setSnackbar", {
        message,
        color: "success",
        timeout: 2000,
      });
      this.$router.push({ name: "video_index" });
    },
    uploadCancel() {
      this.video = { name: this.videoName };
      this.videoPreview = this.videoUrl;
      this.isShowedCancel = false;
    },
    showPreview(videoName, videoUrl) {
      // 編集をキャンセルしたときのため、キャッシュさせる
      this.videoName = videoName;
      this.videoUrl = videoUrl;

      this.uploadCancel();
    },
    checkVideo(isCheckedVideo) {
      this.isCheckedVideo = isCheckedVideo;
    },
    tapLoadingModal(isShowedLoading, loadingMessage) {
      this.isShowedLoading = isShowedLoading;
      this.loadingMessage = loadingMessage;
    },
  },
};
</script>
