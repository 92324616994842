<template>
  <section>
    <div>
      {{ video.name }}
      <span :style="{ cursor: 'pointer' }" @click="reset">×</span>
    </div>

    <div class="my-10 d-flex flex-column align-center">
      <video
        v-if="$route.meta === 'new'"
        :src="videoPreview"
        class="my-2"
        controls
      ></video>
      <iframe
        v-if="$route.meta === 'edit'"
        :src="videoPreview"
        class="my-2"
        :allowfullscreen="true"
      ></iframe>

      <v-btn
        v-if="$route.meta === 'edit'"
        min-height="36px"
        class="my-4"
        rounded
        outlined
        @click="reset"
        >動画を編集する</v-btn
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "VideoNewPreview",
  props: {
    video: [File, Object],
    videoPreview: String,
  },
  data() {
    return {};
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
  },
};
</script>
